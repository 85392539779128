<template>
  <div>
    <b-row v-if="content.id" :id="displayName(content.id)">
      <b-col>
        <b-link
          v-if="names[content.id] !== undefined"
          :to="{
            name: 'object',
            params: {
              id: content.id
            }
          }"
        >
          {{ names[content.id] }}
        </b-link>
      </b-col>
      <b-col sm="auto">
        <b-button
          v-t="'components.inscriptionObject.replace'"
          @click="removePhysicalObject"
        />
      </b-col>
    </b-row>
    <b-row v-if="!content.id">
      {{ $t("components.inscriptionObject.knownToggle") }}
      {{ $t("general.required") }}
      <b-form-checkbox
        v-if="false"
        v-model="knownToggle"
        switch
        @change="changed"
      >
        {{
          knownToggle
            ? $t("components.inscriptionObject.knownObject")
            : $t("components.inscriptionObject.unknownObject")
        }}
      </b-form-checkbox>
      <base-auto-complete
        v-show="knownToggle"
        id="named-objects-selector"
        :graph-query="completions"
        :placeholder="$t('components.inscriptionObject.knownPlaceHolder')"
        :map="nameMap"
        @select="setPhysicalObject"
      >
        <!-- What to show when we have no results -->
        <template v-slot:noResult>
          <span>{{ $t("components.baseList.noResult") }}</span>
        </template>
        <!-- Template for each element in the auto complete -->
        <template v-slot:option="{ option: { name } }">
          <span>{{ name }}</span>
        </template>
        <!-- Template for a selected element in the auto complete -->
        <template v-slot:singleLabel="{ option: { name } }">
          <span>{{ name }}</span>
        </template>
      </base-auto-complete>
      <base-auto-complete
        v-show="!knownToggle"
        id="unnamed-objects-selector"
        :graph-query="unknownCompletions"
        :placeholder="$t('components.inscriptionObject.unknownPlaceHolder')"
        :map="nameMapOnArtefact"
        @select="setPhysicalObject"
      >
        <!-- What to show when we have no results -->
        <template v-slot:noResult>
          <span>{{ $t("components.baseList.noResult") }}</span>
        </template>
        <!-- Template for each element in the auto complete -->
        <template v-slot:option="{ option: { name, id } }">
          <span>{{ name || missingName(id) }}</span>
        </template>
        <!-- Template for a selected element in the auto complete -->
        <template v-slot:singleLabel="{ option: { name, id } }">
          <span>{{ name || missingName(id) }}</span>
        </template>
      </base-auto-complete>
    </b-row>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "InscriptionObject",
  components: {
    BaseAutoComplete: () => import("@/components/BaseAutoComplete")
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      names: {},
      knownToggle: false,
      completions: gql`
        query searchBySignum($search: String!) {
          completions: findPhysicalObjectsBySigna(search: $search) {
            id
            standardSigna {
              signum1
              signum2
            }
          }
        }
      `,
      unknownCompletions: gql`
        query listUnnamedObjects {
          completions: listPhysicalObjectsWithNoName {
            id
            name: artefact
          }
        }
      `
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    nameMap(element) {
      let signa = [];
      element.standardSigna.forEach(s => {
        signa.push(s.signum1 + " " + s.signum2);
      });
      return {
        name: signa.join(", "),
        ...element
      };
    },
    nameMapOnArtefact(element) {
      return {
        name: element.artefact,
        ...element
      };
    },
    setPhysicalObject(element) {
      this.content.id = element.id;
      this.$emit("change");
    },
    removePhysicalObject() {
      this.content.id = null;
      this.$emit("change");
    },
    missingName(id) {
      return this.$t("components.inscriptionObject.missingName", [id]);
    },
    displayName(id) {
      if (this.names[id] != undefined) return;
      this.$apollo
        .query({
          query: gql`
            query physicalObject($id: ID) {
              physicalObject(id: $id) {
                id
                artefact
                standardSigna {
                  signum1
                  signum2
                }
              }
            }
          `,
          variables: { id: id }
        })
        .then(result => {
          let name,
            signa = [];
          result.data.physicalObject.standardSigna.forEach(s => {
            signa.push(s.signum1 + " " + s.signum2);
          });
          if (signa.length > 0) {
            name = signa.join(", ");
          } else if (result.data.physicalObject.artefact) {
            name = result.data.physicalObject.artefact;
          } else {
            name = this.missingName(id);
          }
          this.$set(this.names, result.data.physicalObject.id, name);
        });
    }
  }
};
</script>
